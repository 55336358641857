// パーツの読み込み
@import '../common/_variable.scss'; // 変数設定の読み込み

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// トップ
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.mv-area {
  position: relative;
}
//--------------------------------------------------//
// MV
//--------------------------------------------------//
  .mv {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    min-height: 680px;
    max-height: calc( 100svh - 104px );
    background: $wht;
    overflow: hidden;
    @include break(xl) {
      aspect-ratio: 3/2;
      min-height: auto;
    }
    .mv-slide {
      min-height: 680px;
      max-height: calc( 100svh - 104px );
      @include break(xl) {
        min-height: auto;
      }
      .slick-list,
      .slick-track {
        min-height: 680px;
        max-height: calc( 100svh - 104px );
        @include break(xl) {
          min-height: auto;
        }
      }
      .slick-slide {
        min-height: 680px;
        max-height: calc( 100svh - 104px );
        @include break(xl) {
          min-height: auto;
        }
        .mv-slide__item {
          position: relative;
          display: block;
          width: 100%;
          min-height: 680px;
          max-height: calc( 100svh - 104px );
          overflow: hidden;
          @include break(xl) {
            min-height: auto;
          }
          &::before,
          &::after {
            position: absolute;
            content: "";
            left: 0;
            width: 100%;    
            background: linear-gradient(to bottom, rgba($blk, .8), rgba($blk, 0));
          }
          &::before {
            top: 0;
            height: 20%;
            opacity: .4;
          }
          &::after {
            bottom: 0;
            height: 40%;
            opacity: .6;
            transform: scale(1,-1);
            @include break(m) {
              height: 10%;
              opacity: .2;
            }
          }
          img {
            aspect-ratio: 16/9;
            height: 100%;
            min-height: 680px;
            max-height: calc( 100svh - 104px );
            object-fit: cover;
            @include break(xl) {
              aspect-ratio: 3/2;
              min-height: auto;
            }
          }
        }
      }
      .slick-dots {
        position: absolute;
        @include flex("", flex-end);
        bottom: 24px;
        right: 24px;
        gap: 8px;
        z-index: 2;
        @include break(m) {
          bottom: 20px;
          right: 20px;
        }
        @include break(xs) {
          gap: 6px;
          bottom: 8px;
          right: 8px;
        }
        li {
          position: relative;
          display: block;
          margin-left: auto;
          background: $wht;
          width: 8px;
          height: 8px;
          padding: 0;
          cursor: pointer;
          opacity: .9;
          transition: 0.3s ease-out;
          @include break(m) {
            width: 6px;
            height: 6px;
          }
          @include break(xs) {
            width: 2px;
            height: 2px;
          }
          &.slick-active {
            height: 14px;
            @include break(m) {
              height: 10px;
            }
            @include break(xs) {
              height: 6px;
            }
          }
          button {
            border: 0;
            background: transparent;
            display: block;
            width: 100%;
            height: 100%;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            border-radius: 1px;
            cursor: pointer;
          }
        }
      }
    }
  }


//--------------------------------------------------//
// 当院の取り組みボタン
//--------------------------------------------------//
  .topics-btn {
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 280px;
    transition: 0.3s ease-out;
    z-index: 10;
    @include break(m) {
      position: relative;
      left: auto;
      bottom: auto;
      margin: 40px auto;
      width: 400px;
    }
    @include break(s) {
      width: 320px;
    }
    @include break(xs) {
      margin: 16px auto;
      width: calc( 100% - 32px );
      max-width: 260px;
    }
    a {
      position: relative;
      background: linear-gradient(to right, rgba($blu, .9) 80%, $blu 100%);
      @include flex("", center);
      height: 100%;
      @include break(m) {
        background: $blu;
      }
      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        transition: 0.3s ease-out;
      }
      &::before {
        transform: translateY(-50%);
        right: -14px;
        width: 52px;
        height: 52px;
        background: rgba($wht, .9);
        border: solid 6px $blu;
        border-radius: 50%;
        @include break(m) {
          background: $wht;
        }
        @include break(s) {
          width: 40px;
          height: 40px;
          border-width: 4px;
        }
      }
      &::after {
        transform: translateY(-50%) rotateZ(45deg);
        right: 8px;
        width: 12px;
        height: 12px;
        border-style: solid;
        border-width: 3px 3px 0 0;
        border-color: $blu $blu $tra $tra;
        @include break(s) {
          right: 2px;
          width: 10px;
          height: 10px;
        }
      }
      .topics-btn-inner {
        position: relative;
        padding: 24px 16px 16px 24px;
        width: 100%;
        height: 100%;
        transition: 0.3s ease-out;
        @include break(s) {
          padding: 18px 16px 10px 14px;
        }
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          right: 32px;
          width: 112px;
          height: 88px;
          background: url("../../img/top/topics-btn-deco.svg") center / contain no-repeat;
          opacity: .16;
          @include break(s) {
            right: 16px;
            width: 80px;
            height: 63px;
          }
        }
      }
      .topics-btn-txt {
        display: inline-block;
        color: $wht;
        &__main {
          position: relative;
          display: block;
          margin-bottom: 28px;
          font-size: $fs-xxl;
          line-height: 1;
          @include break(m) {
            font-size: $fs-xl;
          }
          @include break(s) {
            margin-bottom: 20px;
            font-size: $fs-l;
          }
          &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: -14px;
            width: 48px;
            height: 1px;
            background: $wht;
            transition: 0.3s ease-out;
            @include break(s) {
              bottom: -10px;
            }
          }
        }
        &__sub {
          font-size: 1.8rem;
          line-height: 1;
          text-transform: uppercase;
          @include break(m) {
            font-size: $fs-m;
          }
          @include break(s) {
            font-size: $fs-s;
          }
        }
      }
    }
    &:hover {
      width: 282px;
      opacity: .9;
      @include break(m) {
        width: 402px;
      }
      @include break(s) {
        width: 322px;
      }
      @include break(xs) {
        max-width: 262px;
      }
      a {
        &::before {
          right: -14px;
        }
        &::after {
          right: 6px;
          @include break(s) {
            right: 2px;
          }
        }
        .topics-btn-inner {
          padding-left: 26px;
          @include break(s) {
            padding-left: 16px;
          }
        }
        .topics-btn-txt__main::after {
          width: 100%;
          opacity: .8;
        }
      }
    }
  }

//--------------------------------------------------//
// MV下ボタン
//--------------------------------------------------//
  .medical-btn {
    position: relative;
    padding: 40px 0 0;
    z-index: 1;
    @include break(xs) {
      padding: 16px 0 0;
    }
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background: $p-gry;
      z-index: -1;
      @include break(m) {
        height: 290px;
      }
      @include break(s) {
        height: 240px;
      }
      @include break(xs) {
        height: 185px;
      }
    }
    .medical-btn-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      max-width: 1020px;
      margin: 0 auto;
      @include break(l) {
        gap: 16px;
      }
      @include break(m) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include break(xs) {
        gap: 8px;
      }
      &__item {
        a {
          display: block;
          padding: 40px 16px;
          background: $l-yel-grn;
          border-radius: 4px;
          text-align: center;
          @include break(l) {
            padding: 32px 16px;
          }
          @include break(m) {
            padding: 24px;
          }
          @include break(s) {
            padding: 16px;
          }
          @include break(xs) {
            padding: 12px 8px;
          }
          // アイコン
          svg {
            width: 76px;
            height: 76px;
            margin: 0 auto 12px;
            fill: $yel-grn;
            @include break(l) {
              margin: 0 auto 4px;
            }
            @include break(m) {
              width: 56px;
              height: 56px;
            }
            @include break(xs) {
              width: 40px;
              height: 40px;
            }
          }
          // 見出し
          .medical-btn-ttl {
            text-align: center;
            &__main {
              font-size: 1.8rem;
              line-height: 1.2;
              @include break(l) {
                font-size: $fs-m;
              }
              @include break(xs) {
                font-size: $fs-s;
                line-height: 1;
              }
            }
            &__sub {
              color: $yel-grn;
              font-size: $fs-xs;
              font-family: $ff-en;
              line-height: 1.2;
              @include break(s) {
                font-size: $fs-xxs;
              }
            }
          }
          &:hover {
            background: $yel-grn;
            transform: translate(4px,4px);
            svg {
              fill: $wht!important;
            }
            .medical-btn-ttl {
              text-align: center;
              &__main {
                color: $wht;
              }
              &__sub {
                color: $wht!important;
              }
            }
          }
        }
        &:nth-child(2) {
          a {
            background: $l-orn;
            svg {
              fill: $d-orn;
            }
            .medical-btn-ttl {
              &__sub {
                color: $d-orn;
              }
            }
            &:hover {
              background: $d-orn;
            }
          }
        }
        &:nth-child(3) {
          a {
            background: $l-grn;
            svg {
              fill: $d-grn;
            }
            .medical-btn-ttl {
              &__sub {
                color: $d-grn;
              }
            }
            &:hover {
              background: $grn;
            }
          }
        }
        &:nth-child(4) {
          a {
            background: $l-blu;
            svg {
              fill: $d-blu;
            }
            .medical-btn-ttl {
              &__sub {
                color: $d-blu;
              }
            }
            &:hover {
              background: $blu;
            }
          }
        }
      }
    }
  }


//--------------------------------------------------//
// お知らせ
//--------------------------------------------------//
  .news {
    @include break(xs) {
      padding-bottom: 16px;
    }
    .news-unit {
      @include flex;
      gap: 40px;
      @include break(xl) {
        gap: 24px;
      }
      // 見出し ----- //
      &__ttl {
        width: 160px;
        @include break(l) {
          width: 150px;
        }
        @include break(m) {
          @include flex(space-between, center);
          width: 100%;
        }
        .news-headline {
          position: relative;
          margin-bottom: 24px;
          background: $wht;
          @include break(m) {
            margin-bottom: 0;
          }
          &::before {
            position: absolute;
            content: "";
            top: -16px;
            left: -16px;
            width: 120px;
            height: 120px;
            border-radius: 2px;
            background: linear-gradient( to right bottom, $tra, $tra 49.5%, $blk 50%, $blk 50.5%, $wht 51%, $wht 100%);
            z-index: -1;
            @include break(xs) {
              top: -16px;
              left: -12px;
              width: 56px;
              height: 56px;
              background: linear-gradient( to right bottom, $tra, $tra 49.5%, $blk 50.5%, $blk 51%, $wht 51.5%, $wht 100%);
            }
          }
          &__sub {
            font-size: 5.6rem;
            font-family: $ff-en;
            line-height: 1;
            @include break(xs) {
              font-size: 4.8rem;
            }
            span {
              color: $grn;
            }
          }
        }
        .btn-container {
          width: 100%;
          max-width: 200px;
          @include break(xs) {
            max-width: 150px;
          }
        }
      }
      // 記事一覧 ----- //
      &__post {
        width: calc( 100% - 200px );
        @include break(xl) {
          width: calc( 100% - 184px );
        }
        @include break(l) {
          width: calc( 100% - 174px );
        }
        @include break(m) {
          width: 100%;
        }
        // 重要なお知らせ //
        .news-important {
          @include flex;
          @include mb(x4);
          background: rgba($red, .1);
          border-radius: 2px;
          color: $red;
          @include break(xs) {
            padding: 16px 16px 0;
          }
          .news-important-ttl {
            position: relative;
            @include flex(center, center);
            padding: 24px;
            width: 120px;
            @include break(s) {
              padding: 16px;
              width: 88px;
            }
            @include break(xs) {
              padding: 0 0 12px;
              width: 100%;
            }
            br {
              @include break(xs) {
                display: none;
              }
            }
            &::after {
              position: absolute;
              content: "";
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 1px;
              height: calc( 100% - 48px );
              background: $red;
              @include break(xs) {
                top: 100%;
                transform: none;
                width: 100%;
                height: 1px;
              }
            }
          }
          .news-important-list {
            padding: 0 24px;
            width: calc( 100% - 120px );
            @include break(s) {
              padding: 0 16px;
              width: calc( 100% - 88px );
            }
            @include break(xs) {
              padding: 0;
              width: 100%;
            }
            &__item {
              @include flex;
              padding: 12px 0 11px;
              a {
                @include flex;
                width: 100%;
                color: $red;
                .item-date {
                  padding: 0 8px;
                  width: 130px;
                  @include break(m) {
                    width: 110px;
                  }
                  @include break(xs) {
                    padding: 0 8px 0 0;
                    width: 92px;
                  }
                }
                .item-ttl {
                  position: relative;
                  padding: 0 16px 0 0;
                  width: calc( 100% - 130px );
                  @include break(m) {
                    width: calc( 100% - 110px );
                  }
                  @include break(xs) {
                    padding: 0 8px 0 0;
                    width: calc( 100% - 92px );
                  }
                  &::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 4px;
                    transform: translateY(-50%) rotateZ(45deg);
                    width: 5px;
                    height: 5px;
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    border-color: $red $red $tra $tra;
                    @include break(xs) {
                      right: 1px;
                    }
                  }
                }
                &:hover {
                  transform: translateX(2px);
                  opacity: .8;
                }
              }
              &:not(:last-child) {
                border-bottom: dashed 1px $red;
              }
              &:first-child {
                padding-top: 16px;
              }
              &:last-child {
                padding-bottom: 16px;
              }
            }
          }
        }
        // 通常のお知らせ //
        .news-regular {
          .news-tabs {
            .tablist {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              @include break(xs) {
                grid-template-columns: repeat(2, 1fr);
                gap: 4px;
              }
              button {
                position: relative;
                display: inline-block;
                height: 48px;
                text-align: center;
                z-index: 2;
                cursor: pointer;
                font-size: $fs-m;
                color: $blk;
                @include break(m) {
                  height: 42px;
                }
                @include break(xs) {
                  height: 38px;
                  border: solid 1px rgba($gry, .48);
                  font-size: $fs-s;
                }
                span {
                  position: relative;
                  &::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: -14px;
                    transform: translateY(-50%);
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background: $blu;
                    opacity: 0;
                    @include break(xs) {
                      width: 28px;
                      height: 28px;
                    }
                  }
                }
                &[aria-selected="true"] {
                  @include break(xs) {
                    border-color: rgba($blu, .48);
                  }
                  color: $blu;
                  span {
                    &::before { background: $blu; opacity: .05; }
                  }
                }
                //最後のタブボタン以外は右に点線
                &:not(:first-of-type) {
                  position: relative;
                  &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 18px;
                    border-right: dashed 1px $blk;
                    @include break(xs) {
                      display: none;
                    }
                  }
                }
                &:nth-of-type(2) {
                  &[aria-selected="true"] {
                    @include break(xs) {
                      border-color: rgba($yel-grn, .48);
                    }
                    color: $yel-grn;
                    span {
                      &::before { background: $yel-grn; }
                    }
                  }
                }
                &:nth-of-type(3) {
                  &[aria-selected="true"] {
                    @include break(xs) {
                      border-color: rgba($d-orn, .48);
                    }
                    color: $d-orn;
                    span {
                      &::before { background: $d-orn; }
                    }
                  }
                }
                &:nth-of-type(4) {
                  &[aria-selected="true"] {
                    @include break(xs) {
                      border-color: rgba($red, .48);
                    }
                    color: $red;
                    span {
                      &::before { background: $red; }
                    }
                  }
                }
                &:nth-of-type(5) {
                  &[aria-selected="true"] {
                    @include break(xs) {
                      border-color: rgba($grn, .48);
                    }
                    color: $grn;
                    span {
                      &::before { background: $grn; }
                    }
                  }
                }
              }
            }
            [role="tabpanel"] {
              position: relative;
              width: calc( 100% - 4px );
              margin: 8px 0 0;
              padding: 8px 0 0;
              border-top: solid 2px $blk;
              z-index: 1;
              &.is-hidden {
                display: none;
              }
            }
            .news-sub-btn {
              padding-top: 12px;
              text-align: right;
              @include break(tb) {
                padding-top: 16px;
              }
              a {
                position: relative;
                padding-left: 24px;
                span {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 18px;
                  height: 6px;
                  transition: 0.3s ease-out;
                  overflow: hidden;
                  svg {
                    position: absolute;
                    right: 0;
                    top: calc( 50% - 2px );
                    transform: translateY(-50%);
                    width: 24px;
                    height: 6px;
                    fill: $blu;
                    transition: 0.3s ease-out;
                  }
                }
                &:hover {
                  text-decoration: underline;
                  span {
                    transform: translateY(-50%) translateX(2px);
                  }
                }
              }
            }
            #media-panel {
              .news-sub-btn a span svg {
                fill: $yel-grn;
              }
            }
            #event-panel {
              .news-sub-btn a span svg {
                fill: $orn;
              }
            }
            #seminar-panel {
              .news-sub-btn a span svg {
                fill: $red;
              }
            }
            #recruit-panel {
              .news-sub-btn a span svg {
                fill: $grn;
              }
            }
          }
          .information-list {
            &__item {
              border-bottom: dashed 1px $gry;
              a {
                @include flex;
                padding: 16px 0;
                @include break(m) {
                  padding: 12px 0;
                }
                @include break(s) {
                  padding: 14px 0;
                }
                .information-dtl {
                  @include flex(space-between,center);
                  width: 215px;
                  @include break(s) {
                    margin-bottom: 10px;
                    width: 185px;
                  }
                  &__date {
                    padding: 0 8px;
                  }
                  &__cate {
                    position: relative;
                    padding-left: 16px;
                    width: 84px;
                    color: $aqu;
                    @include break(s) {
                      padding-left: 12px;
                    }
                    @include break(xs) {
                      padding-left: 10px;
                    }
                    &::before {
                      position: absolute;
                      content: "";
                      left: 0;
                      top: 7px;
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      border-style: solid;
                      border-width: 2px;
                      border-color: $aqu;
                      line-height: 1;
                      @include break(s) {
                        top: 4px;
                      }
                      @include break(xs) {
                        top: 5px;
                        width: 7px;
                        height: 7px;
                      }
                    }
                    &.cate-media {
                      color: $yel-grn;
                      &::before { border-color: $yel-grn; }
                    }
                    &.cate-event {
                      color: $d-orn;
                      &::before { border-color: $d-orn; }
                    }
                    &.cate-seminar {
                      color: $red;
                      &::before { border-color: $red; }
                    }
                    &.cate-recruit {
                      color: $grn;
                      &::before { border-color: $grn; }
                    }
                  }
                }
                .information__ttl {
                  position: relative;
                  width: calc( 100% - 215px );
                  display: inline-block;
                  padding: 0 16px 0 48px;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  @include break(l) {
                    padding: 0 8px 0 20px;
                  }
                  @include break(s) {
                    width: 100%;
                    padding: 0 8px;
                  }
                  &::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 4px;
                    transform: translateY(-50%) rotateZ(45deg);
                    width: 5px;
                    height: 5px;
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    border-color: $blk $blk $tra $tra;
                    @include break(xs) {
                      right: 1px;
                    }
                  }
                }
                &:hover {
                  transform: translateX(2px);
                  opacity: .8;
                }
              }
            }
          }
        }
      }
    }
  }

//--------------------------------------------------//
// うぶね
//--------------------------------------------------//
  .ubune {
    .wrap {
      position: relative;
      padding-bottom: 64px;
      @include break(m) {
        padding-bottom: 56px;
      }
      &::before {
        position: absolute;
        content: "";
        left: 60px;
        bottom: 0;
        width: 58px;
        height: 40px;
        background: url("../../img/top/info-deco-u.svg") center / cover no-repeat;
        @include break(s) {
          left: calc( 100% - 80px );
        }
      }
    }
    .ubune-unit {
      @include flex;
      gap: 40px;
      @include break(xl) {
        gap: 24px;
      }
      // 見出し ----- //
      &__ttl {
        width: 160px;
        @include break(l) {
          width: 150px;
        }
        @include break(m) {
          @include flex(space-between, center);
          width: 100%;
        }
        .ubune-headline {
          margin-bottom: 24px;
          @include break(m) {
            margin-bottom: 0;
          }
          &__sub {
            display: inline-block;
            margin-bottom: 2px;
            max-width: 200px;
            @include break(xs) {
              max-width: 120px;
            }
          }
          &__main {
            @include break(xs) {
              font-size: $fs-s;
            }
            br {
              @include break(m) {
                display: none;
              }
              @include break(xs) {
                display: block;
              }
            }
          }
        }
        .btn-container {
          width: 100%;
          max-width: 200px;
          @include break(xs) {
            max-width: 150px;
          }
          .btn {
            gap: 10px;
            @include break(xs) {
              gap: 6px;
            }
          }
        }
      }
      // 記事一覧 ----- //
      &__post {
        width: calc( 100% - 200px );
        @include break(xl) {
          width: calc( 100% - 184px );
        }
        @include break(l) {
          width: calc( 100% - 174px );
        }
        @include break(m) {
          width: 100%;
        }
        .ubune-list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 32px;
          @include break(xl) {
            gap: 16px;
          }
          @include break(s) {
            grid-template-columns: 1fr;
            gap: 12px;
          }
          &__item {
            a {
              display: block;
              @include break(s) {
                @include flex("", center);
                gap: 24px;
              }
              @include break(xs) {
                gap: 16px;
              }
              &:hover {
                .ubune-eyecatch {
                  img {
                    transform: translateX(-50%) translateY(-50%) scale(1.1);
                  }
                }
                .ubune-ttl {
                  transform: translateX(2px);
                  opacity: .64;
                }
              }
            }
            .ubune-eyecatch {
              position: relative;
              display: block;
              aspect-ratio: 3/2;
              overflow: hidden;
              background: $l-gry;
              @include break(s) {
                width: 230px;
              }
              @include break(xs) {
                width: 150px;
              }
              img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                object-fit: contain;
                height: 100%;
                border-radius: 2px;
                transition: 0.3s ease-out;
              }
            }
            .ubune-dtl {
              @include break(s) {
                width: calc( 100% - 254px );
              }
              @include break(xs) {
                width: calc( 100% - 166px );
              }
              .ubune-dtl-upper {
                @include flex(space-between, center);
                padding: 14px 0 8px;
                @include break(s) {
                  padding: 0 0 20px;
                }
                @include break(xs) {
                  padding: 0 0 8px;
                }
                .ubune-meta {
                  @include flex(space-between, center);
                  width: 100%;
                  margin-bottom: 14px;
                  @include break(xs) {
                    margin-bottom: 8px;
                  }
                  &__vol {
                    margin-right: 12px;
                    padding: 8px 8px 6px;
                    background: $l-gry;
                    border-radius: 1px;
                    font-size: $fs-xs;
                    line-height: 1;
                    @include break(xs) {
                      padding: 5px 8px 4px;
                    }
                    span {
                      display: inline-block;
                      margin-left: 2px;
                      font-size: $fs-s;
                      @include break(xs) {
                        font-size: $fs-xs;
                      }
                    }
                  }
                  &__date {
                    line-height: 1;
                    @include break(xl) {
                      font-size: $fs-s;
                    }
                    @include break(xs) {
                      font-size: $fs-xs;
                    }
                  }
                }
                .ubune-cate {
                  position: relative;
                  width: 100%;
                  padding-left: 12px;
                  color: $red;
                  line-height: 1;
                  font-size: $fs-s;
                  @include break(xs) {
                    padding-left: 8px;
                    font-size: $fs-xs;
                  }
                  &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 2px;
                    width: 8px;
                    height: 8px;
                    border: solid 2px $red;
                    border-radius: 50%;
                    @include break(xs) {
                      top: 2px;
                      width: 6px;
                      height: 6px;
                    }
                  }
                  &.cate-forefront {
                    color: $aqu;
                    &::before { border-color: $aqu; }
                  }
                  &.cate-close-up {
                    color: $orn;
                    &::before { border-color: $orn; }
                  }
                  &.cate-new {
                    color: $yel-grn;
                    &::before { border-color: $yel-grn; }
                  }
                  &.cate-table {
                    color: $grn;
                    &::before { border-color: $grn; }
                  }
                  &.cate-calisthenics {
                    color: $d-orn;
                    &::before { border-color: $d-orn; }
                  }
                  &.cate-qa {
                    color: $d-grn;
                    &::before { border-color: $d-grn; }
                  }
                  &.cate-anniversary {
                    color: $blu;
                    &::before { border-color: $blu; }
                  }
                  &.cate-topics {
                    color: $d-blu;
                    &::before { border-color: $d-blu; }
                  }
                }
              }
              .ubune-ttl {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                transition: 0.3s ease-out;
              }
            }
            &:not(:last-child) {
              @include break(s) {
                padding-bottom: 12px;
                border-bottom: dashed 1px $gry;
              }
            }
          }
        }
      }
    }
  }


//--------------------------------------------------//
// 病院の情報
//--------------------------------------------------//
  .hosp-info {
    position: relative;
    @include pt(x8);
    @include pb(x8);
    overflow: hidden;
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) scale(1.01);
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &::before {
      background: url("../../img/top/hosp-info-bg.jpg") center / cover no-repeat;
      filter: blur(3px);
    }
    &::after {
      background: rgba($wht, .4);
    }
    .info-unit {
      display: grid;
      grid-template-columns: 670px 1fr;
      gap: 32px;
      padding: 40px;
      background: rgba($wht, .96);
      border-radius: 4px;
      @include break(xl) {
        grid-template-columns: 1fr 280px;
        gap: 24px;
        padding: 32px;
      }
      @include break(m) {
        grid-template-columns: 1fr;
      }
      @include break(s) {
        gap: 20px;
        padding: 24px;
        font-size: $fs-s;
      }
      @include break(xs) {
        gap: 12px;
        padding: 16px;
      }
      // 情報 ----- //
      &__dtl {
        .info-dtl-list {
          margin-bottom: 24px;
          &__item {
            @include flex(space-between, center);
            dt {
              width: 72px;
              color: $blu;
              font-weight: bold;
            }
            dd {
              width: calc( 100% - 96px );
              a {
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                }
              }
              br {
                @include break(xl) {
                  display: block;
                }
                @include break(m) {
                  display: none;
                }
              }
            }
            &:not(:last-child) {
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: dashed 2px $gry;
            }
          }
        }
        // 電話番号
        .info-dtl-tel {
          @include flex;
          padding: 32px 0;
          background: $blu;
          border-radius: 2px;
          @include break(xl) {
            padding: 16px;
          }
          @include break(m) {
            @include flex;
            gap: 16px;
            padding: 0;
            background: none;
          }
          @include break(s) {
            gap: 12px;
          }
          &__item {
            padding: 0 24px;
            @include break(xl) {
              padding: 0;
              text-align: center;
            }
            @include break(m) {
              @include flex("", center);
              padding: 12px;
              background: rgba($aqu, .08);
            }
            .item-inner {
              @include break(m) {
                @include flex;
                width: 100%;
              }
            }
            a {
              @include break(m) {
                display: block;
                width: 100%;
                padding: 12px;
                background: $blu;
              }
              span {
                position: relative;
                padding-left: 24px;
                color: $wht;
                font-size: $fs-xl;
                line-height: 1.4;
                font-family: $ff-en;
                @include break(m) {
                  font-size: $fs-l;
                }
                svg {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                  fill: $wht;
                }
              }
            }
            .tel-txt {
              color: $wht;
              font-size: $fs-s;
              text-align: center;
              @include break(m) {
                width: 100%;
                margin-bottom: 8px;
                color: $blu;
                order: -1;
              }
            }
            // 代表電話
            &.--main-tel {
              width: 35%;
              border-right: solid 1px $wht;
              @include break(xl) {
                margin-bottom: 12px;
                padding-bottom: 12px;
                width: 100%;
                border-right: none;
                border-bottom: solid 1px $wht;
              }
              @include break(m) {
                width: calc( 50% - 8px );
                margin-bottom: 0;
                border-bottom: none;
              }
              @include break(s) {
                width: 100%;
              }
            }
            // 予約センター電話
            &.--reservation-tel {
              width: 65%;
              @include break(xl) {
                width: 100%;
              }
              @include break(m) {
                width: calc( 50% - 8px );
              }
              @include break(s) {
                width: 100%;
              }
              // 予約センター電話A
              .reservation-telA {
                position: relative;
                margin-right: 6px;
                padding-right: 14px;
                @include break(m) {
                  margin-right: 0;
                  margin-bottom: 8px;
                }
                &::after {
                  position: absolute;
                  content: "";
                  top: calc( 50% - 3px );
                  right: 0;
                  width: 2px;
                  height: 20px;
                  background: $wht;
                  transform: translateY(-50%) rotateZ(18deg);
                  @include break(m) {
                    display: none;
                  }
                }
              }
              // 予約センター電話B
              .reservation-telB {
                span {
                  svg {
                    @include break(m) {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
      // ボタン ----- //
      &__btn {
        .btn {
          gap: 16px;
          @include break(m) {
            gap: 8px;
          }
          a {
            border-color: $blu;
            max-width: 320px;
            min-height: 66px;
            padding: 0 24px;
            font-size: 1.8rem;
            justify-content: flex-start;
            color: $blu;
            @include break(xl) {
              min-height: 90px;
              padding: 10px;
              font-size: $fs-m;
            }
            @include break(m) {
              justify-content: center;
              width: calc( 33.33% - 6px );
            }
            @include break(s) {
              font-size: $fs-s;
            }
            @include break(xs) {
              min-height: 64px;
              padding: 16px 24px;
              width: 100%;
              max-width: 520px;
              justify-content: flex-start;
            }
            span {
              position: relative;
              padding: 0 0 0 46px;
              @include break(m) {
                padding: 0 0 0 36px;
              }
              // アイコン
              svg {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 32px;
                height: 32px;
                fill: $blu;
                @include break(m) {
                  width: 28px;
                  height: 28px;
                }
                @include break(xs) {
                  left: 0;
                  top: 50%;
                  width: 28px;
                  height: 28px;
                  transform: translateY(-50%);
                }
              }
              br {
                display: none;
                @include break(s) {
                  display: block;
                }
                @include break(xs) {
                  display: none;
                }
              }
            }
            // 矢印
            .ico-arrow {
              fill: $blu;
              @include break(xl) {
                right: 16px;
              }
              @include break(m) {
                top: calc( 100% - 16px );
                transform: none;
                right: 8px;
              }
            }
            &:hover {
              background: $blu;
              color: $wht;
              span {
                svg {
                  fill: $wht;
                }
              }
              .ico-arrow {
                fill: $wht;
              }
            }
          }
        }
      }
    }
  }