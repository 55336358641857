.mv-area {
  position: relative;
}

.mv {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  min-height: 680px;
  max-height: calc( 100svh - 104px);
  background: #ffffff;
  overflow: hidden;
}

@media screen and (max-width: 1280px) {
  .mv {
    aspect-ratio: 3/2;
    min-height: auto;
  }
}

.mv .mv-slide {
  min-height: 680px;
  max-height: calc( 100svh - 104px);
}

@media screen and (max-width: 1280px) {
  .mv .mv-slide {
    min-height: auto;
  }
}

.mv .mv-slide .slick-list,
.mv .mv-slide .slick-track {
  min-height: 680px;
  max-height: calc( 100svh - 104px);
}

@media screen and (max-width: 1280px) {
  .mv .mv-slide .slick-list,
  .mv .mv-slide .slick-track {
    min-height: auto;
  }
}

.mv .mv-slide .slick-slide {
  min-height: 680px;
  max-height: calc( 100svh - 104px);
}

@media screen and (max-width: 1280px) {
  .mv .mv-slide .slick-slide {
    min-height: auto;
  }
}

.mv .mv-slide .slick-slide .mv-slide__item {
  position: relative;
  display: block;
  width: 100%;
  min-height: 680px;
  max-height: calc( 100svh - 104px);
  overflow: hidden;
}

@media screen and (max-width: 1280px) {
  .mv .mv-slide .slick-slide .mv-slide__item {
    min-height: auto;
  }
}

.mv .mv-slide .slick-slide .mv-slide__item::before, .mv .mv-slide .slick-slide .mv-slide__item::after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(35, 31, 32, 0.8), rgba(35, 31, 32, 0));
}

.mv .mv-slide .slick-slide .mv-slide__item::before {
  top: 0;
  height: 20%;
  opacity: .4;
}

.mv .mv-slide .slick-slide .mv-slide__item::after {
  bottom: 0;
  height: 40%;
  opacity: .6;
  transform: scale(1, -1);
}

@media screen and (max-width: 960px) {
  .mv .mv-slide .slick-slide .mv-slide__item::after {
    height: 10%;
    opacity: .2;
  }
}

.mv .mv-slide .slick-slide .mv-slide__item img {
  aspect-ratio: 16/9;
  height: 100%;
  min-height: 680px;
  max-height: calc( 100svh - 104px);
  object-fit: cover;
}

@media screen and (max-width: 1280px) {
  .mv .mv-slide .slick-slide .mv-slide__item img {
    aspect-ratio: 3/2;
    min-height: auto;
  }
}

.mv .mv-slide .slick-dots {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  bottom: 24px;
  right: 24px;
  gap: 8px;
  z-index: 2;
}

@media screen and (max-width: 960px) {
  .mv .mv-slide .slick-dots {
    bottom: 20px;
    right: 20px;
  }
}

@media screen and (max-width: 520px) {
  .mv .mv-slide .slick-dots {
    gap: 6px;
    bottom: 8px;
    right: 8px;
  }
}

.mv .mv-slide .slick-dots li {
  position: relative;
  display: block;
  margin-left: auto;
  background: #ffffff;
  width: 8px;
  height: 8px;
  padding: 0;
  cursor: pointer;
  opacity: .9;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  .mv .mv-slide .slick-dots li {
    width: 6px;
    height: 6px;
  }
}

@media screen and (max-width: 520px) {
  .mv .mv-slide .slick-dots li {
    width: 2px;
    height: 2px;
  }
}

.mv .mv-slide .slick-dots li.slick-active {
  height: 14px;
}

@media screen and (max-width: 960px) {
  .mv .mv-slide .slick-dots li.slick-active {
    height: 10px;
  }
}

@media screen and (max-width: 520px) {
  .mv .mv-slide .slick-dots li.slick-active {
    height: 6px;
  }
}

.mv .mv-slide .slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  border-radius: 1px;
  cursor: pointer;
}

.topics-btn {
  position: absolute;
  left: 0;
  bottom: 32px;
  width: 280px;
  transition: 0.3s ease-out;
  z-index: 10;
}

@media screen and (max-width: 960px) {
  .topics-btn {
    position: relative;
    left: auto;
    bottom: auto;
    margin: 40px auto;
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .topics-btn {
    width: 320px;
  }
}

@media screen and (max-width: 520px) {
  .topics-btn {
    margin: 16px auto;
    width: calc( 100% - 32px);
    max-width: 260px;
  }
}

.topics-btn a {
  position: relative;
  background: linear-gradient(to right, rgba(73, 119, 173, 0.9) 80%, #4977ad 100%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .topics-btn a {
    background: #4977ad;
  }
}

.topics-btn a::before, .topics-btn a::after {
  position: absolute;
  content: "";
  top: 50%;
  transition: 0.3s ease-out;
}

.topics-btn a::before {
  transform: translateY(-50%);
  right: -14px;
  width: 52px;
  height: 52px;
  background: rgba(255, 255, 255, 0.9);
  border: solid 6px #4977ad;
  border-radius: 50%;
}

@media screen and (max-width: 960px) {
  .topics-btn a::before {
    background: #ffffff;
  }
}

@media screen and (max-width: 768px) {
  .topics-btn a::before {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }
}

.topics-btn a::after {
  transform: translateY(-50%) rotateZ(45deg);
  right: 8px;
  width: 12px;
  height: 12px;
  border-style: solid;
  border-width: 3px 3px 0 0;
  border-color: #4977ad #4977ad transparent transparent;
}

@media screen and (max-width: 768px) {
  .topics-btn a::after {
    right: 2px;
    width: 10px;
    height: 10px;
  }
}

.topics-btn a .topics-btn-inner {
  position: relative;
  padding: 24px 16px 16px 24px;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .topics-btn a .topics-btn-inner {
    padding: 18px 16px 10px 14px;
  }
}

.topics-btn a .topics-btn-inner::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: 32px;
  width: 112px;
  height: 88px;
  background: url("../../img/top/topics-btn-deco.svg") center/contain no-repeat;
  opacity: .16;
}

@media screen and (max-width: 768px) {
  .topics-btn a .topics-btn-inner::after {
    right: 16px;
    width: 80px;
    height: 63px;
  }
}

.topics-btn a .topics-btn-txt {
  display: inline-block;
  color: #ffffff;
}

.topics-btn a .topics-btn-txt__main {
  position: relative;
  display: block;
  margin-bottom: 28px;
  font-size: 2.8rem;
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .topics-btn a .topics-btn-txt__main {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 768px) {
  .topics-btn a .topics-btn-txt__main {
    margin-bottom: 20px;
    font-size: 2rem;
  }
}

.topics-btn a .topics-btn-txt__main::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  width: 48px;
  height: 1px;
  background: #ffffff;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .topics-btn a .topics-btn-txt__main::after {
    bottom: -10px;
  }
}

.topics-btn a .topics-btn-txt__sub {
  font-size: 1.8rem;
  line-height: 1;
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  .topics-btn a .topics-btn-txt__sub {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .topics-btn a .topics-btn-txt__sub {
    font-size: 1.4rem;
  }
}

.topics-btn:hover {
  width: 282px;
  opacity: .9;
}

@media screen and (max-width: 960px) {
  .topics-btn:hover {
    width: 402px;
  }
}

@media screen and (max-width: 768px) {
  .topics-btn:hover {
    width: 322px;
  }
}

@media screen and (max-width: 520px) {
  .topics-btn:hover {
    max-width: 262px;
  }
}

.topics-btn:hover a::before {
  right: -14px;
}

.topics-btn:hover a::after {
  right: 6px;
}

@media screen and (max-width: 768px) {
  .topics-btn:hover a::after {
    right: 2px;
  }
}

.topics-btn:hover a .topics-btn-inner {
  padding-left: 26px;
}

@media screen and (max-width: 768px) {
  .topics-btn:hover a .topics-btn-inner {
    padding-left: 16px;
  }
}

.topics-btn:hover a .topics-btn-txt__main::after {
  width: 100%;
  opacity: .8;
}

.medical-btn {
  position: relative;
  padding: 40px 0 0;
  z-index: 1;
}

@media screen and (max-width: 520px) {
  .medical-btn {
    padding: 16px 0 0;
  }
}

.medical-btn::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: #fafafa;
  z-index: -1;
}

@media screen and (max-width: 960px) {
  .medical-btn::before {
    height: 290px;
  }
}

@media screen and (max-width: 768px) {
  .medical-btn::before {
    height: 240px;
  }
}

@media screen and (max-width: 520px) {
  .medical-btn::before {
    height: 185px;
  }
}

.medical-btn .medical-btn-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1020px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .medical-btn .medical-btn-list {
    gap: 16px;
  }
}

@media screen and (max-width: 960px) {
  .medical-btn .medical-btn-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 520px) {
  .medical-btn .medical-btn-list {
    gap: 8px;
  }
}

.medical-btn .medical-btn-list__item a {
  display: block;
  padding: 40px 16px;
  background: #f3f6e0;
  border-radius: 4px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .medical-btn .medical-btn-list__item a {
    padding: 32px 16px;
  }
}

@media screen and (max-width: 960px) {
  .medical-btn .medical-btn-list__item a {
    padding: 24px;
  }
}

@media screen and (max-width: 768px) {
  .medical-btn .medical-btn-list__item a {
    padding: 16px;
  }
}

@media screen and (max-width: 520px) {
  .medical-btn .medical-btn-list__item a {
    padding: 12px 8px;
  }
}

.medical-btn .medical-btn-list__item a svg {
  width: 76px;
  height: 76px;
  margin: 0 auto 12px;
  fill: #7b9e14;
}

@media screen and (max-width: 1024px) {
  .medical-btn .medical-btn-list__item a svg {
    margin: 0 auto 4px;
  }
}

@media screen and (max-width: 960px) {
  .medical-btn .medical-btn-list__item a svg {
    width: 56px;
    height: 56px;
  }
}

@media screen and (max-width: 520px) {
  .medical-btn .medical-btn-list__item a svg {
    width: 40px;
    height: 40px;
  }
}

.medical-btn .medical-btn-list__item a .medical-btn-ttl {
  text-align: center;
}

.medical-btn .medical-btn-list__item a .medical-btn-ttl__main {
  font-size: 1.8rem;
  line-height: 1.2;
}

@media screen and (max-width: 1024px) {
  .medical-btn .medical-btn-list__item a .medical-btn-ttl__main {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 520px) {
  .medical-btn .medical-btn-list__item a .medical-btn-ttl__main {
    font-size: 1.4rem;
    line-height: 1;
  }
}

.medical-btn .medical-btn-list__item a .medical-btn-ttl__sub {
  color: #7b9e14;
  font-size: 1.2rem;
  font-family: "Jost", sans-serif;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .medical-btn .medical-btn-list__item a .medical-btn-ttl__sub {
    font-size: 1rem;
  }
}

.medical-btn .medical-btn-list__item a:hover {
  background: #7b9e14;
  transform: translate(4px, 4px);
}

.medical-btn .medical-btn-list__item a:hover svg {
  fill: #ffffff !important;
}

.medical-btn .medical-btn-list__item a:hover .medical-btn-ttl {
  text-align: center;
}

.medical-btn .medical-btn-list__item a:hover .medical-btn-ttl__main {
  color: #ffffff;
}

.medical-btn .medical-btn-list__item a:hover .medical-btn-ttl__sub {
  color: #ffffff !important;
}

.medical-btn .medical-btn-list__item:nth-child(2) a {
  background: #fff3dd;
}

.medical-btn .medical-btn-list__item:nth-child(2) a svg {
  fill: #f08306;
}

.medical-btn .medical-btn-list__item:nth-child(2) a .medical-btn-ttl__sub {
  color: #f08306;
}

.medical-btn .medical-btn-list__item:nth-child(2) a:hover {
  background: #f08306;
}

.medical-btn .medical-btn-list__item:nth-child(3) a {
  background: #def0ed;
}

.medical-btn .medical-btn-list__item:nth-child(3) a svg {
  fill: #066f68;
}

.medical-btn .medical-btn-list__item:nth-child(3) a .medical-btn-ttl__sub {
  color: #066f68;
}

.medical-btn .medical-btn-list__item:nth-child(3) a:hover {
  background: #22a8a3;
}

.medical-btn .medical-btn-list__item:nth-child(4) a {
  background: #E2E8F0;
}

.medical-btn .medical-btn-list__item:nth-child(4) a svg {
  fill: #153875;
}

.medical-btn .medical-btn-list__item:nth-child(4) a .medical-btn-ttl__sub {
  color: #153875;
}

.medical-btn .medical-btn-list__item:nth-child(4) a:hover {
  background: #4977ad;
}

@media screen and (max-width: 520px) {
  .news {
    padding-bottom: 16px;
  }
}

.news .news-unit {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

@media screen and (max-width: 1280px) {
  .news .news-unit {
    gap: 24px;
  }
}

.news .news-unit__ttl {
  width: 160px;
}

@media screen and (max-width: 1024px) {
  .news .news-unit__ttl {
    width: 150px;
  }
}

@media screen and (max-width: 960px) {
  .news .news-unit__ttl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.news .news-unit__ttl .news-headline {
  position: relative;
  margin-bottom: 24px;
  background: #ffffff;
}

@media screen and (max-width: 960px) {
  .news .news-unit__ttl .news-headline {
    margin-bottom: 0;
  }
}

.news .news-unit__ttl .news-headline::before {
  position: absolute;
  content: "";
  top: -16px;
  left: -16px;
  width: 120px;
  height: 120px;
  border-radius: 2px;
  background: linear-gradient(to right bottom, transparent, transparent 49.5%, #231f20 50%, #231f20 50.5%, #ffffff 51%, #ffffff 100%);
  z-index: -1;
}

@media screen and (max-width: 520px) {
  .news .news-unit__ttl .news-headline::before {
    top: -16px;
    left: -12px;
    width: 56px;
    height: 56px;
    background: linear-gradient(to right bottom, transparent, transparent 49.5%, #231f20 50.5%, #231f20 51%, #ffffff 51.5%, #ffffff 100%);
  }
}

.news .news-unit__ttl .news-headline__sub {
  font-size: 5.6rem;
  font-family: "Jost", sans-serif;
  line-height: 1;
}

@media screen and (max-width: 520px) {
  .news .news-unit__ttl .news-headline__sub {
    font-size: 4.8rem;
  }
}

.news .news-unit__ttl .news-headline__sub span {
  color: #22a8a3;
}

.news .news-unit__ttl .btn-container {
  width: 100%;
  max-width: 200px;
}

@media screen and (max-width: 520px) {
  .news .news-unit__ttl .btn-container {
    max-width: 150px;
  }
}

.news .news-unit__post {
  width: calc( 100% - 200px);
}

@media screen and (max-width: 1280px) {
  .news .news-unit__post {
    width: calc( 100% - 184px);
  }
}

@media screen and (max-width: 1024px) {
  .news .news-unit__post {
    width: calc( 100% - 174px);
  }
}

@media screen and (max-width: 960px) {
  .news .news-unit__post {
    width: 100%;
  }
}

.news .news-unit__post .news-important {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(8px * 4);
  background: rgba(230, 53, 2, 0.1);
  border-radius: 2px;
  color: #e63502;
}

@media screen and (max-width: 960px) {
  .news .news-unit__post .news-important {
    margin-bottom: calc((8px * 4) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important {
    margin-bottom: calc((8px * 4) * 0.5);
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important {
    padding: 16px 16px 0;
  }
}

.news .news-unit__post .news-important .news-important-ttl {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 120px;
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-important .news-important-ttl {
    padding: 16px;
    width: 88px;
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-ttl {
    padding: 0 0 12px;
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-ttl br {
    display: none;
  }
}

.news .news-unit__post .news-important .news-important-ttl::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: calc( 100% - 48px);
  background: #e63502;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-ttl::after {
    top: 100%;
    transform: none;
    width: 100%;
    height: 1px;
  }
}

.news .news-unit__post .news-important .news-important-list {
  padding: 0 24px;
  width: calc( 100% - 120px);
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-important .news-important-list {
    padding: 0 16px;
    width: calc( 100% - 88px);
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-list {
    padding: 0;
    width: 100%;
  }
}

.news .news-unit__post .news-important .news-important-list__item {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 11px;
}

.news .news-unit__post .news-important .news-important-list__item a {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: #e63502;
}

.news .news-unit__post .news-important .news-important-list__item a .item-date {
  padding: 0 8px;
  width: 130px;
}

@media screen and (max-width: 960px) {
  .news .news-unit__post .news-important .news-important-list__item a .item-date {
    width: 110px;
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-list__item a .item-date {
    padding: 0 8px 0 0;
    width: 92px;
  }
}

.news .news-unit__post .news-important .news-important-list__item a .item-ttl {
  position: relative;
  padding: 0 16px 0 0;
  width: calc( 100% - 130px);
}

@media screen and (max-width: 960px) {
  .news .news-unit__post .news-important .news-important-list__item a .item-ttl {
    width: calc( 100% - 110px);
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-list__item a .item-ttl {
    padding: 0 8px 0 0;
    width: calc( 100% - 92px);
  }
}

.news .news-unit__post .news-important .news-important-list__item a .item-ttl::before {
  position: absolute;
  content: "";
  top: 50%;
  right: 4px;
  transform: translateY(-50%) rotateZ(45deg);
  width: 5px;
  height: 5px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #e63502 #e63502 transparent transparent;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-important .news-important-list__item a .item-ttl::before {
    right: 1px;
  }
}

.news .news-unit__post .news-important .news-important-list__item a:hover {
  transform: translateX(2px);
  opacity: .8;
}

.news .news-unit__post .news-important .news-important-list__item:not(:last-child) {
  border-bottom: dashed 1px #e63502;
}

.news .news-unit__post .news-important .news-important-list__item:first-child {
  padding-top: 16px;
}

.news .news-unit__post .news-important .news-important-list__item:last-child {
  padding-bottom: 16px;
}

.news .news-unit__post .news-regular .news-tabs .tablist {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button {
  position: relative;
  display: inline-block;
  height: 48px;
  text-align: center;
  z-index: 2;
  cursor: pointer;
  font-size: 1.6rem;
  color: #231f20;
}

@media screen and (max-width: 960px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button {
    height: 42px;
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button {
    height: 38px;
    border: solid 1px rgba(204, 204, 204, 0.48);
    font-size: 1.4rem;
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button span {
  position: relative;
}

.news .news-unit__post .news-regular .news-tabs .tablist button span::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -14px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #4977ad;
  opacity: 0;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button span::before {
    width: 28px;
    height: 28px;
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button[aria-selected="true"] {
  color: #4977ad;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button[aria-selected="true"] {
    border-color: rgba(73, 119, 173, 0.48);
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button[aria-selected="true"] span::before {
  background: #4977ad;
  opacity: .05;
}

.news .news-unit__post .news-regular .news-tabs .tablist button:not(:first-of-type) {
  position: relative;
}

.news .news-unit__post .news-regular .news-tabs .tablist button:not(:first-of-type)::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 18px;
  border-right: dashed 1px #231f20;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button:not(:first-of-type)::before {
    display: none;
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(2)[aria-selected="true"] {
  color: #7b9e14;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(2)[aria-selected="true"] {
    border-color: rgba(123, 158, 20, 0.48);
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(2)[aria-selected="true"] span::before {
  background: #7b9e14;
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(3)[aria-selected="true"] {
  color: #f08306;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(3)[aria-selected="true"] {
    border-color: rgba(240, 131, 6, 0.48);
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(3)[aria-selected="true"] span::before {
  background: #f08306;
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(4)[aria-selected="true"] {
  color: #e63502;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(4)[aria-selected="true"] {
    border-color: rgba(230, 53, 2, 0.48);
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(4)[aria-selected="true"] span::before {
  background: #e63502;
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(5)[aria-selected="true"] {
  color: #22a8a3;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(5)[aria-selected="true"] {
    border-color: rgba(34, 168, 163, 0.48);
  }
}

.news .news-unit__post .news-regular .news-tabs .tablist button:nth-of-type(5)[aria-selected="true"] span::before {
  background: #22a8a3;
}

.news .news-unit__post .news-regular .news-tabs [role="tabpanel"] {
  position: relative;
  width: calc( 100% - 4px);
  margin: 8px 0 0;
  padding: 8px 0 0;
  border-top: solid 2px #231f20;
  z-index: 1;
}

.news .news-unit__post .news-regular .news-tabs [role="tabpanel"].is-hidden {
  display: none;
}

.news .news-unit__post .news-regular .news-tabs .news-sub-btn {
  padding-top: 12px;
  text-align: right;
}

.news .news-unit__post .news-regular .news-tabs .news-sub-btn a {
  position: relative;
  padding-left: 24px;
}

.news .news-unit__post .news-regular .news-tabs .news-sub-btn a span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 6px;
  transition: 0.3s ease-out;
  overflow: hidden;
}

.news .news-unit__post .news-regular .news-tabs .news-sub-btn a span svg {
  position: absolute;
  right: 0;
  top: calc( 50% - 2px);
  transform: translateY(-50%);
  width: 24px;
  height: 6px;
  fill: #4977ad;
  transition: 0.3s ease-out;
}

.news .news-unit__post .news-regular .news-tabs .news-sub-btn a:hover {
  text-decoration: underline;
}

.news .news-unit__post .news-regular .news-tabs .news-sub-btn a:hover span {
  transform: translateY(-50%) translateX(2px);
}

.news .news-unit__post .news-regular .news-tabs #media-panel .news-sub-btn a span svg {
  fill: #7b9e14;
}

.news .news-unit__post .news-regular .news-tabs #event-panel .news-sub-btn a span svg {
  fill: #f8b726;
}

.news .news-unit__post .news-regular .news-tabs #seminar-panel .news-sub-btn a span svg {
  fill: #e63502;
}

.news .news-unit__post .news-regular .news-tabs #recruit-panel .news-sub-btn a span svg {
  fill: #22a8a3;
}

.news .news-unit__post .news-regular .information-list__item {
  border-bottom: dashed 1px #cccccc;
}

.news .news-unit__post .news-regular .information-list__item a {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

@media screen and (max-width: 960px) {
  .news .news-unit__post .news-regular .information-list__item a {
    padding: 12px 0;
  }
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-regular .information-list__item a {
    padding: 14px 0;
  }
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 215px;
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-regular .information-list__item a .information-dtl {
    margin-bottom: 10px;
    width: 185px;
  }
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__date {
  padding: 0 8px;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate {
  position: relative;
  padding-left: 16px;
  width: 84px;
  color: #1aa9db;
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-regular .information-list__item a .information-dtl__cate {
    padding-left: 12px;
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .information-list__item a .information-dtl__cate {
    padding-left: 10px;
  }
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate::before {
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #1aa9db;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-regular .information-list__item a .information-dtl__cate::before {
    top: 4px;
  }
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .information-list__item a .information-dtl__cate::before {
    top: 5px;
    width: 7px;
    height: 7px;
  }
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-media {
  color: #7b9e14;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-media::before {
  border-color: #7b9e14;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-event {
  color: #f08306;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-event::before {
  border-color: #f08306;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-seminar {
  color: #e63502;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-seminar::before {
  border-color: #e63502;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-recruit {
  color: #22a8a3;
}

.news .news-unit__post .news-regular .information-list__item a .information-dtl__cate.cate-recruit::before {
  border-color: #22a8a3;
}

.news .news-unit__post .news-regular .information-list__item a .information__ttl {
  position: relative;
  width: calc( 100% - 215px);
  display: inline-block;
  padding: 0 16px 0 48px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media screen and (max-width: 1024px) {
  .news .news-unit__post .news-regular .information-list__item a .information__ttl {
    padding: 0 8px 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .news .news-unit__post .news-regular .information-list__item a .information__ttl {
    width: 100%;
    padding: 0 8px;
  }
}

.news .news-unit__post .news-regular .information-list__item a .information__ttl::before {
  position: absolute;
  content: "";
  top: 50%;
  right: 4px;
  transform: translateY(-50%) rotateZ(45deg);
  width: 5px;
  height: 5px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #231f20 #231f20 transparent transparent;
}

@media screen and (max-width: 520px) {
  .news .news-unit__post .news-regular .information-list__item a .information__ttl::before {
    right: 1px;
  }
}

.news .news-unit__post .news-regular .information-list__item a:hover {
  transform: translateX(2px);
  opacity: .8;
}

.ubune .wrap {
  position: relative;
  padding-bottom: 64px;
}

@media screen and (max-width: 960px) {
  .ubune .wrap {
    padding-bottom: 56px;
  }
}

.ubune .wrap::before {
  position: absolute;
  content: "";
  left: 60px;
  bottom: 0;
  width: 58px;
  height: 40px;
  background: url("../../img/top/info-deco-u.svg") center/cover no-repeat;
}

@media screen and (max-width: 768px) {
  .ubune .wrap::before {
    left: calc( 100% - 80px);
  }
}

.ubune .ubune-unit {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

@media screen and (max-width: 1280px) {
  .ubune .ubune-unit {
    gap: 24px;
  }
}

.ubune .ubune-unit__ttl {
  width: 160px;
}

@media screen and (max-width: 1024px) {
  .ubune .ubune-unit__ttl {
    width: 150px;
  }
}

@media screen and (max-width: 960px) {
  .ubune .ubune-unit__ttl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.ubune .ubune-unit__ttl .ubune-headline {
  margin-bottom: 24px;
}

@media screen and (max-width: 960px) {
  .ubune .ubune-unit__ttl .ubune-headline {
    margin-bottom: 0;
  }
}

.ubune .ubune-unit__ttl .ubune-headline__sub {
  display: inline-block;
  margin-bottom: 2px;
  max-width: 200px;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__ttl .ubune-headline__sub {
    max-width: 120px;
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__ttl .ubune-headline__main {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 960px) {
  .ubune .ubune-unit__ttl .ubune-headline__main br {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__ttl .ubune-headline__main br {
    display: block;
  }
}

.ubune .ubune-unit__ttl .btn-container {
  width: 100%;
  max-width: 200px;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__ttl .btn-container {
    max-width: 150px;
  }
}

.ubune .ubune-unit__ttl .btn-container .btn {
  gap: 10px;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__ttl .btn-container .btn {
    gap: 6px;
  }
}

.ubune .ubune-unit__post {
  width: calc( 100% - 200px);
}

@media screen and (max-width: 1280px) {
  .ubune .ubune-unit__post {
    width: calc( 100% - 184px);
  }
}

@media screen and (max-width: 1024px) {
  .ubune .ubune-unit__post {
    width: calc( 100% - 174px);
  }
}

@media screen and (max-width: 960px) {
  .ubune .ubune-unit__post {
    width: 100%;
  }
}

.ubune .ubune-unit__post .ubune-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

@media screen and (max-width: 1280px) {
  .ubune .ubune-unit__post .ubune-list {
    gap: 16px;
  }
}

@media screen and (max-width: 768px) {
  .ubune .ubune-unit__post .ubune-list {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}

.ubune .ubune-unit__post .ubune-list__item a {
  display: block;
}

@media screen and (max-width: 768px) {
  .ubune .ubune-unit__post .ubune-list__item a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item a {
    gap: 16px;
  }
}

.ubune .ubune-unit__post .ubune-list__item a:hover .ubune-eyecatch img {
  transform: translateX(-50%) translateY(-50%) scale(1.1);
}

.ubune .ubune-unit__post .ubune-list__item a:hover .ubune-ttl {
  transform: translateX(2px);
  opacity: .64;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-eyecatch {
  position: relative;
  display: block;
  aspect-ratio: 3/2;
  overflow: hidden;
  background: #eeeeee;
}

@media screen and (max-width: 768px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-eyecatch {
    width: 230px;
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-eyecatch {
    width: 150px;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-eyecatch img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: contain;
  height: 100%;
  border-radius: 2px;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl {
    width: calc( 100% - 254px);
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl {
    width: calc( 100% - 166px);
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0 8px;
}

@media screen and (max-width: 768px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper {
    padding: 0 0 20px;
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper {
    padding: 0 0 8px;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta {
    margin-bottom: 8px;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__vol {
  margin-right: 12px;
  padding: 8px 8px 6px;
  background: #eeeeee;
  border-radius: 1px;
  font-size: 1.2rem;
  line-height: 1;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__vol {
    padding: 5px 8px 4px;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__vol span {
  display: inline-block;
  margin-left: 2px;
  font-size: 1.4rem;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__vol span {
    font-size: 1.2rem;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__date {
  line-height: 1;
}

@media screen and (max-width: 1280px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__date {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-meta__date {
    font-size: 1.2rem;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate {
  position: relative;
  width: 100%;
  padding-left: 12px;
  color: #e63502;
  line-height: 1;
  font-size: 1.4rem;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate {
    padding-left: 8px;
    font-size: 1.2rem;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate::before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  width: 8px;
  height: 8px;
  border: solid 2px #e63502;
  border-radius: 50%;
}

@media screen and (max-width: 520px) {
  .ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate::before {
    top: 2px;
    width: 6px;
    height: 6px;
  }
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-forefront {
  color: #1aa9db;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-forefront::before {
  border-color: #1aa9db;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-close-up {
  color: #f8b726;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-close-up::before {
  border-color: #f8b726;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-new {
  color: #7b9e14;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-new::before {
  border-color: #7b9e14;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-table {
  color: #22a8a3;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-table::before {
  border-color: #22a8a3;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-calisthenics {
  color: #f08306;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-calisthenics::before {
  border-color: #f08306;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-qa {
  color: #066f68;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-qa::before {
  border-color: #066f68;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-anniversary {
  color: #4977ad;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-anniversary::before {
  border-color: #4977ad;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-topics {
  color: #153875;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-dtl-upper .ubune-cate.cate-topics::before {
  border-color: #153875;
}

.ubune .ubune-unit__post .ubune-list__item .ubune-dtl .ubune-ttl {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 768px) {
  .ubune .ubune-unit__post .ubune-list__item:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: dashed 1px #cccccc;
  }
}

.hosp-info {
  position: relative;
  padding-top: calc(8px * 8);
  padding-bottom: calc(8px * 8);
  overflow: hidden;
}

@media screen and (max-width: 960px) {
  .hosp-info {
    padding-top: calc((8px * 8) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .hosp-info {
    padding-top: calc((8px * 8) * 0.5);
  }
}

@media screen and (max-width: 960px) {
  .hosp-info {
    padding-bottom: calc((8px * 8) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .hosp-info {
    padding-bottom: calc((8px * 8) * 0.5);
  }
}

.hosp-info::before, .hosp-info::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1.01);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hosp-info::before {
  background: url("../../img/top/hosp-info-bg.jpg") center/cover no-repeat;
  filter: blur(3px);
}

.hosp-info::after {
  background: rgba(255, 255, 255, 0.4);
}

.hosp-info .info-unit {
  display: grid;
  grid-template-columns: 670px 1fr;
  gap: 32px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.96);
  border-radius: 4px;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit {
    grid-template-columns: 1fr 280px;
    gap: 24px;
    padding: 32px;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .hosp-info .info-unit {
    gap: 20px;
    padding: 24px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 520px) {
  .hosp-info .info-unit {
    gap: 12px;
    padding: 16px;
  }
}

.hosp-info .info-unit__dtl .info-dtl-list {
  margin-bottom: 24px;
}

.hosp-info .info-unit__dtl .info-dtl-list__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.hosp-info .info-unit__dtl .info-dtl-list__item dt {
  width: 72px;
  color: #4977ad;
  font-weight: bold;
}

.hosp-info .info-unit__dtl .info-dtl-list__item dd {
  width: calc( 100% - 96px);
}

.hosp-info .info-unit__dtl .info-dtl-list__item dd a {
  text-decoration: underline;
}

.hosp-info .info-unit__dtl .info-dtl-list__item dd a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__dtl .info-dtl-list__item dd br {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-list__item dd br {
    display: none;
  }
}

.hosp-info .info-unit__dtl .info-dtl-list__item:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: dashed 2px #cccccc;
}

.hosp-info .info-unit__dtl .info-dtl-tel {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0;
  background: #4977ad;
  border-radius: 2px;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__dtl .info-dtl-tel {
    padding: 16px;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0;
    background: none;
  }
}

@media screen and (max-width: 768px) {
  .hosp-info .info-unit__dtl .info-dtl-tel {
    gap: 12px;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item {
  padding: 0 24px;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item {
    padding: 0;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px;
    background: rgba(26, 169, 219, 0.08);
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item .item-inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item a {
    display: block;
    width: 100%;
    padding: 12px;
    background: #4977ad;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item a span {
  position: relative;
  padding-left: 24px;
  color: #ffffff;
  font-size: 2.4rem;
  line-height: 1.4;
  font-family: "Jost", sans-serif;
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item a span {
    font-size: 2rem;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item a span svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  fill: #ffffff;
}

.hosp-info .info-unit__dtl .info-dtl-tel__item .tel-txt {
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item .tel-txt {
    width: 100%;
    margin-bottom: 8px;
    color: #4977ad;
    order: -1;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item.--main-tel {
  width: 35%;
  border-right: solid 1px #ffffff;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--main-tel {
    margin-bottom: 12px;
    padding-bottom: 12px;
    width: 100%;
    border-right: none;
    border-bottom: solid 1px #ffffff;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--main-tel {
    width: calc( 50% - 8px);
    margin-bottom: 0;
    border-bottom: none;
  }
}

@media screen and (max-width: 768px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--main-tel {
    width: 100%;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel {
  width: 65%;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel {
    width: calc( 50% - 8px);
  }
}

@media screen and (max-width: 768px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel {
    width: 100%;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel .reservation-telA {
  position: relative;
  margin-right: 6px;
  padding-right: 14px;
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel .reservation-telA {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel .reservation-telA::after {
  position: absolute;
  content: "";
  top: calc( 50% - 3px);
  right: 0;
  width: 2px;
  height: 20px;
  background: #ffffff;
  transform: translateY(-50%) rotateZ(18deg);
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel .reservation-telA::after {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__dtl .info-dtl-tel__item.--reservation-tel .reservation-telB span svg {
    display: block;
  }
}

.hosp-info .info-unit__btn .btn {
  gap: 16px;
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__btn .btn {
    gap: 8px;
  }
}

.hosp-info .info-unit__btn .btn a {
  border-color: #4977ad;
  max-width: 320px;
  min-height: 66px;
  padding: 0 24px;
  font-size: 1.8rem;
  justify-content: flex-start;
  color: #4977ad;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__btn .btn a {
    min-height: 90px;
    padding: 10px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__btn .btn a {
    justify-content: center;
    width: calc( 33.33% - 6px);
  }
}

@media screen and (max-width: 768px) {
  .hosp-info .info-unit__btn .btn a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 520px) {
  .hosp-info .info-unit__btn .btn a {
    min-height: 64px;
    padding: 16px 24px;
    width: 100%;
    max-width: 520px;
    justify-content: flex-start;
  }
}

.hosp-info .info-unit__btn .btn a span {
  position: relative;
  padding: 0 0 0 46px;
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__btn .btn a span {
    padding: 0 0 0 36px;
  }
}

.hosp-info .info-unit__btn .btn a span svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  fill: #4977ad;
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__btn .btn a span svg {
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 520px) {
  .hosp-info .info-unit__btn .btn a span svg {
    left: 0;
    top: 50%;
    width: 28px;
    height: 28px;
    transform: translateY(-50%);
  }
}

.hosp-info .info-unit__btn .btn a span br {
  display: none;
}

@media screen and (max-width: 768px) {
  .hosp-info .info-unit__btn .btn a span br {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .hosp-info .info-unit__btn .btn a span br {
    display: none;
  }
}

.hosp-info .info-unit__btn .btn a .ico-arrow {
  fill: #4977ad;
}

@media screen and (max-width: 1280px) {
  .hosp-info .info-unit__btn .btn a .ico-arrow {
    right: 16px;
  }
}

@media screen and (max-width: 960px) {
  .hosp-info .info-unit__btn .btn a .ico-arrow {
    top: calc( 100% - 16px);
    transform: none;
    right: 8px;
  }
}

.hosp-info .info-unit__btn .btn a:hover {
  background: #4977ad;
  color: #ffffff;
}

.hosp-info .info-unit__btn .btn a:hover span svg {
  fill: #ffffff;
}

.hosp-info .info-unit__btn .btn a:hover .ico-arrow {
  fill: #ffffff;
}
